@import url('https://fonts.googleapis.com/css?family=Overpass:400,400i,700&display=swap');

* {
  &:focus {
    outline: none;
  }
}

html {
  font-size: 100%;
}

body {
  background: #232325;
  margin: 0;
  font-family: "Overpass", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 1.2rem;
  padding-bottom: 160px;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }
}

.btn {
  background: #111;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 8px 12px;
  color: #fff;
  box-shadow: 0 2px 7px rgba(29, 29, 29, 0.22);
  cursor: pointer;
  white-space: nowrap;
}

.headerInfo {
  padding: 80px 0 0;
  text-align: center;

  .logo {
    a {
      font-size: 42px;
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      text-decoration: none;
      letter-spacing: 4px;
      line-height: 1;
      vertical-align: bottom;
    }

    img {
      max-width: 160px;
    }
  }

  .app-description {
    color: rgba(255, 255, 255, 0.35);
    font-weight: 500;
  }

  .dev-description {
    font-size: 13px;
    font-weight: 600;
    color: #545454;
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 50%;
    border-radius: 3px;
    background: #1d1c1c;
    transform: translateX(-50%);
    padding: 3px 10px;
    box-shadow: 0 1px 2px rgba(17, 17, 17, 0.50);
    
    a {
      border-radius: 3px;
      color: #545454;
      text-decoration: none;

      &:hover {
        color: #999;
      }
    }

    span {
      color: #E91E63;
    }
  }
}

.popup {
  display: inline-block;
  position: relative;

  .popupTrigger {
    font-size: 18px;
    background: #111111;
    padding: 4px;
    border-radius: 50px;
    width: 8px;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-left: 5px;
    cursor: pointer;
  }

  .popupContent {
    position: absolute;
    z-index: 52;
    display: none;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    
    .contentWrap {
      background: #111;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 4px 10px #1111117d;
      white-space: nowrap;
      font-size: 13px;
      
      p {
        margin-top: 0;
      }

      ul {
        padding: 0 0px 0 20px;
        margin-bottom: 0;
        line-height: 1.6;
  
        span {
          color: #e6e6e6;
        }
      }
    }

  }

  &:hover {
    .popupContent {
      display: flex;

      .contentWrap {
        -webkit-animation: showPopup 0.15s forwards ease-in-out;
        animation: showPopup 0.15s forwards ease-in-out;
      }
    }
  }
}

@keyframes showPopup {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
