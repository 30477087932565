@import "../styles/variables.scss";

.optionsPanel {
  .option {
    .optionHeading {
      margin-bottom: 10px;
      font-size: 14px;
      color: #9a9a9a;
      font-weight: 500;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    .optionValue {
      position: relative;

      input[type="file"] {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0;
        cursor: pointer;
        font-size: 0px;
      }

      select {
        background: #111;
        border: 1px solid #111;
        font-size: 1.2rem;
        padding: 4px 30px 4px 15px;
        color: #fff;
        height: 40px;
        cursor: pointer;
        -webkit-appearance: none;
      }

      .colorOption {
        background: #fff;
        width: 30px;
        height: 30px;
        display: inline-block;
        border-radius: 100px;
        position: relative;
        cursor: pointer;
        box-shadow: 0 2px 8px rgba(17, 17, 17, 0.5);

        &:not(:last-child) {
          margin-right: 12px;
        }

        input[type="radio"] {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }

        &.active {
          transform: scale(1.15);
          transition: all 0.1s ease-in-out;

          &:after {
            content: "";
            width: 100%;
            height: 100%;
            border: 2px solid #fff;
            border-radius: 100px;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
          }
        }

        &.white {
          background: #fff;
        }

        &.red {
          background: #f96b6c;
        }

        &.yellow {
          background: #f2dc60;
        }

        &.green {
          background: #97e563;
        }

        &.blue {
          background: #33a2db;
        }

        &.black {
          background: #222;
        }

        &.gold {
          background: #f9e7d3;
        }
      }
    }

    &.option-selectColor {
      .optionValue {
        display: flex;
        height: 40px;
        align-items: center;
      }
    }

    &.option-selectDevice {
      .optionValue {
        position: relative;

        &:after {
          content: "\25BE";
          right: 10px;
          top: 47%;
          transform: translateY(-50%) scaleX(2);
          position: absolute;
          font-size: 22px;
        }
      }
    }
  }

  .saveActions {
    .btn {
      width: 100%;
      background: $primary;
      border-radius: 5px;
      border-color: $primary;
      font-weight: 600;
    }
  }
}

.playground {
  position: fixed;
  z-index: 50;
  bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    position: relative;
    margin: 40px 0 0;
    width: 100%;
  }

  .optionsPanel {
    display: flex;
    align-items: flex-end;
    background: rgba(17, 17, 17, 0.8);
    border-radius: 12px;
    padding: 30px 20px;
    box-shadow: 0 4px 10px #1111117d;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      border-radius: 0;
      align-items: center;
    }

    .option {
      &:not(:last-child) {
        margin-right: 30px;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
        margin-right: 0 !important;
      }
    }
  }
}
